/* *{
  background-color: #000;
} */
.landing-page {
  background-color: #141414;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden; 
  /* height: 68.3vh; */
}

.timer-container {
  position: absolute;
  margin-left: -30px;
}

.maharashtra-image {
  height: 60%;
  width: 30%;
  position: absolute;
  bottom: 0;
  left: 60%; 
  top: 18rem;
  object-fit: cover;
  background-repeat: repeat-x;
  background-color: #141414;
}


.info-container {
  margin-left: 4.25rem; 
  font-family: 'Roboto Mono', monospace;
  text-align: left;
  line-height: 3.5rem;
  position: absolute;
  z-index: 1;
  top: 13rem;
}

.line-1 {
  font-size: 1.5rem; 
  margin: 0;
  font-weight: 400;
}

.line-2 {
  font-size: 2.75rem; 
  font-weight: 700;
  margin: 0;
}

.red-color {
  color: red; /* Change the color of the "<<" characters to red */
}

.line-3 {
  font-size: 1.2rem; 
  margin: 0;
  color: #A09E9E;
  font-weight: 400;
}

.register-button {
  margin-top: 20rem;
}

.getearlypass {
  position: static;
  bottom: 20px;
  z-index: 999;
}

.tags {
  color: #A09E9E;
  font-weight: 400;
  margin-top:2rem;
  margin-left: 4rem; 
}

.socials{
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  margin-top: 100px;
}
.socials2{
  display: none;
}

.maharashtra-image-mobile{
  display: none;
}


@media (min-width: 768px) and (max-width: 1024px) {
  .landing-page {
    height: auto;
  }
  
  .info-container {
    margin-left: 0.5rem; 
    font-family: 'Roboto Mono', monospace;
    text-align: left;
    line-height: 2.5rem;
    position: absolute;
    z-index: 1;
    top: 10rem;
  }

  .line-1 {
    font-size: 1rem; 
    margin: 10px;
    font-weight: 200;
  }
  
  .line-2 {
    font-size: 1.8rem; 
    font-weight: 700;
    margin: 10px;
  }
  
  .red-color {
    display: none; 
  }
  
  .line-3 {
    font-size: 0.85rem; 
    margin: 10px;
    color: #A09E9E;
    font-weight: 200;
  }


  .register-button {
    font-size: 16px;
    margin-top: 0;
    margin-right: 0rem;
    z-index: 1;
  }

  .socials{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 40px;
  }
  .socials2 {
    display: none;
  }

  .conference{
    flex-direction: column;
  }

  .maharashtra-image {
    display: none;
  }

  .maharashtra-image-mobile{
    /* display: flex; */
    display: block;
    height: auto;
    width: 50%;
    position: relative; 
    bottom: 10px; 
    left: 20px; 
  }

} 


@media (max-width: 768px) {
  .landing-page {
    height: auto;
  }

  .info-container {
    margin-left: 0.5rem; 
    font-family: 'Roboto Mono', monospace;
    text-align: left;
    line-height: 2.5rem;
    position: absolute;
    z-index: 1;
    top: 10rem;
  }

  .line-1 {
    font-size: 1rem; 
    margin: 10px;
    font-weight: 200;
  }
  
  .line-2 {
    font-size: 1.8rem; 
    font-weight: 700;
    margin: 10px;
  }
  
  .red-color {
    display: none; 
  }
  
  .line-3 {
    font-size: 0.85rem; 
    margin: 10px;
    color: #A09E9E;
    font-weight: 200;
  }


  .conference{
    flex-direction: column;
  }

  
  .maharashtra-image{
    display: none;
  }

  .maharashtra-image-mobile{
    display: flex;
    height: auto;
    position: absolute; 
    bottom: -30px;
  }


  .getearlypass {
    width: 75%; 
    position: fixed;
    bottom: 20px;
    z-index: 999; 
  }

  .timer-container {
    display: none;
  }

  .socials{
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 0;
    bottom: 20px;
  }

  .register-button {
    margin-top: 0rem;
    margin-bottom: 10rem;
  }
}