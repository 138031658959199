.pricing_container{
  background-color: #141414;
  padding: 60px 100px;
  display: flex;
  flex-direction: row;
}

.pricing_intro {
    color: white;
    margin-top: 30px;
}

.pricing_header {
    font-size: 40px;
    font-weight: 600;
    margin-left: 10px;
}

.orange_text {
    color: orange;
  }


.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.list-item {
flex: 0 0 calc(33.33% - 20px); 
margin: 10px;
list-style-type: none;
color: white;
display: flex;
align-items: center;
}

.list-item:nth-child(odd) {
color: #f0f0f0; 
}

.red-tick {
    color: red;
    margin-right: 5px; 
  }

.getyourpass {
    margin-top: 40px;
}

@media (max-width: 768px) {

  .pricing_container{
    padding: 60px 20px;
    align-items: center;
    justify-content: center;
  }

  .pricing_header {
    font-size: 30px;
    font-weight: 600;
    margin-left: 10px;
}
  .flex-container {
    flex-direction: column; /* Change to column for smaller screens */
  }

  .aeroplane-image {
    display: none; /* Hide aeroplane image on mobile screens */
  }

  .getyourpass {
    display: none;
}

}