/* CountDownTimer.css */

.countdown-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141414;
    padding: 10px;
    margin-top: 10px;
  }
  
  .timer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    background-color: black;
    border: 1px solid white;
    border-radius: 12px;
    margin: 0 15px;
  }
  
  .timer-number {
    font-size: 24px;
    color: white;
    margin-bottom: 5px; /* Adjust the margin here */
  }
  
  .timer-label {
    font-size: 14px;
    color: white;
    margin-top: -30px;
  }
  